
import {computed, defineComponent, onMounted, ref,} from "vue";
import store from "@/store";
import {Actions} from "@/store/enums/StoreEnums";
import {ErrorMessage, Field, Form} from "vee-validate";
import flatpickr from "flatpickr";
import {Turkish} from "flatpickr/dist/l10n/tr";
import 'flatpickr/dist/flatpickr.css';
import {hideModal} from "@/core/helpers/dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
import {dateConvert} from "@/core/filters/datatime";



export default defineComponent({
  name: "return-invoice",
  components: {
    Field,
  },

  props: {
    invoiceID: String,
  },

  watch: {
    invoiceID() {
      const payloadDetails = {
        "ID": this.invoiceID

      };
      store.dispatch(Actions.INVOICE_RETURNS_DETAILS, payloadDetails);

      const payloadItems = {
        "ID": this.invoiceID,
      };
      store.dispatch(Actions.INVOICES_GETVOUCHERS, payloadItems);


    },
  },

  setup(props, {emit}) {

    onMounted(() => {
      setDatePicker();
      arrCheckedReservations.value=[];
    });

    let isEmit = true;
    const arrCheckedReservations = ref([]);
    const arrVouchers = ref([]);
    const submitButtonRef = ref<null | HTMLButtonElement>(null);

    const objChecked = ref ({ReservationID:0, VoucherNo:0, Amount:0 })

    const invoicesgetVouchers = computed(() => {
      const datacomputed = store.getters.invoicesgetVouchers
      fillVouchers(datacomputed);
      return  datacomputed;
    })

    const invoiceReturnsDetails = computed(() => {
      return store.getters.invoiceReturnsDetails;
    })

/*
    store.dispatch(Actions.INVOICES_LIST, {'ID': props.invoiceID});
    const invoicesList = computed(() => {
      const datacomputed = store.getters.invoicesList
      return  datacomputed;
    })
*/


    function fillVouchers(prmArr) {
      arrVouchers.value = [];
      prmArr.forEach(each => {
        let founded = arrVouchers.value.find((el)=>{return el===each.VoucherNo})
        console.log("ReturnInvoices fillVoucher " + founded + " " + each.VoucherNo);
        if(arrVouchers.value.find((el)=>{return el===each.VoucherNo})===undefined ){
          console.log("ReturnInvoices fillVoucher pushed " + founded + " " + each.VoucherNo);
          arrVouchers.value.push(each.VoucherNo);
        }
      });


    }




    function clickCheckbox(prmVoucherNo) {
      const vvClassName = ("inpGetVouchers"+prmVoucherNo);
      arrCheckedReservations.value=[];
      const arrElements = document.getElementsByClassName(vvClassName);
      for (let i=0; i<arrElements.length; i++){
        let eachElemnt = arrElements[i] as HTMLInputElement;
        if (eachElemnt.checked) {
          arrCheckedReservations.value.push(eachElemnt.getAttribute("voucherNo"));
        }
      }
      console.log("ReturnInvoice clickCheckbox Res ID : " + prmVoucherNo);
    }

    const submit = () => {
      if (!submitButtonRef.value) {
        return;
      }
      const payload1 = {
        ID: props.invoiceID,
        ReturnDate:invoiceReturnsDetails.value.ReturnDate ? dateConvert.method.flatpickerToDateYM(invoiceReturnsDetails.value.ReturnDate) : "",
        Amount:invoiceReturnsDetails.value.Amount,
        ReturnInvoiceNo:JSON.stringify(arrCheckedReservations.value),
       // ReturnVoucherNos:JSON.stringify(arrCheckedReservations.value),
      }
      store.dispatch(Actions.INVOICE_RETURNS_BULKINSERT, payload1)

          .then(() => {
            isEmit = true;

            store.dispatch(Actions.INVOICE_RETURNS_DETAILS, {'ID': props.invoiceID});
            Swal.fire({
              text: "Tebrikler. Başarılı bir şekilde işlemi tamamladınız.",
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: "Geri Dön!",
              customClass: {
                confirmButton: "btn fw-bold btn-light-primary",
              },
            }).then(function () {
              submitButtonRef.value.disabled = false;
              submitButtonRef.value.setAttribute("data-kt-indicator", "off");
            });
          //  location.reload();
          }).catch(() => {
        Swal.fire({
          text: store.getters.getInvoicesErrors[0],
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "Tekrar dene!",
          customClass: {
            confirmButton: "btn fw-bold btn-light-danger",
          },
        });
        submitButtonRef.value.disabled = false;
        submitButtonRef.value.setAttribute("data-kt-indicator", "off");
      });

    };

    function setDatePicker() {
      let tmpInvoiceDate = flatpickr("#InvoiceDate", {"locale": Turkish, dateFormat: "d-m-Y", defaultDate: 'today',});
    }

    return {
      submitButtonRef,
      submit,
      //invoicesList,
      invoiceReturnsDetails,
      invoicesgetVouchers,
      clickCheckbox,
      arrVouchers,
    };
  },
});
