

import {computed, defineComponent, ref} from "vue";
import MasterConfig from "@/core/config/MasterConfig";
import * as Yup from "yup";
import Swal from "sweetalert2/dist/sweetalert2.js";
import store from "@/store";
import {Actions} from "@/store/enums/StoreEnums";

export default defineComponent({

    props: {
        propUploadType: String,
        propIsShowSuccessMessage: {type: Boolean, required: false, default:true,},
    },

    setup(props, {emit}) {

        const refForm = ref<null | HTMLFormElement>(null);
        const fileInputs = ref<null | HTMLElement>(null);
        const supportedFiles = MasterConfig.upload.format;
        const chkFileSize = ref(true);
        const chkFileExt = ref(true);
        const chkFile = ref(true);

        const validationSchema = Yup.object().shape({
            fileTitle: Yup.string().required().label("Dosya Başlığı"),
        });

        function addFileAndSendEmail(prmFile, prmKindName, prmDirName, prmFileName, prmEmailType, prmEmailTo, prmEmailToName, prmEmailFromName, prmEmailSubject, prmAccountID, prmParentID, prmContentValue) {
            console.log("addFileAndSendEmail prmAccountID: "+prmAccountID+" prmDirName: '"+prmDirName+"'");

            prmFileName = prmFileName.replace(/[^0-9a-zA-Z.]/g, "");
            const vvFile = prmFile;

            const vvPayload = {
                Title: "Title1",
                Description: "Desc",
                ParentID: prmParentID,
                ChildID: 0,
                SubChildID: 0,
                Type: props.propUploadType,
                FileName: prmFileName,

            }


            console.log("addFileAndSendEmail vvPayload: "+JSON.stringify(vvPayload));
            console.log("addFileAndSendEmail vvFile: "+JSON.stringify(vvFile));

            let formData = new FormData();
            formData.append("file", vvFile, prmFileName);


            let vvIsSuccess = false;

            fetch(MasterConfig.general.uploadPath, {
                method: "POST",
                body: formData,
            }).then(() => {
                store.dispatch(Actions.FILES_INSERT, vvPayload) .then(() => {

                    let vvPayloadEmail = {
                        "AccountID": prmAccountID,
                        "Type": prmEmailType,
                        "To": prmEmailTo,
                        "Cc": "",
                        "FileName": prmFileName,
                        "DirName": prmDirName,
                        "ToName": prmEmailToName,
                        "FromName": prmEmailFromName,
                        "Subject": prmEmailSubject,
                        "ContentValue": prmContentValue,
                        "KindName": prmKindName,
                    };


                    store.dispatch(Actions.FLIGHT_RESERVATION_SEND_EMAIL, vvPayloadEmail).then((prmRes)=>{
                        console.log("prmRes: "+JSON.stringify(prmRes));
                        if (prmRes.resdata!==undefined && prmRes.resdata!==null) {
                            console.log("prmRes v1 "+prmRes.resdata.status);
                            if (prmRes.resdata.status!==undefined && prmRes.resdata.status===200) {
                                console.log("prmRes v2 ");
                                console.log("prmRes.resdata.data: "+JSON.stringify(prmRes.resdata.data));
                                console.log("prmRes.resdata.data.Status: "+prmRes.resdata.data.Status);

                                if (prmRes.resdata.data.Status==="SENT") {
                                    vvIsSuccess = true;
                                    emit("on-success");
                                    if (props.propIsShowSuccessMessage){
                                      Swal.fire({
                                        text: "Tebrikler. Başarılı bir şekilde email atıldı.", confirmButtonText: "Geri Dön!",
                                        icon: "success", buttonsStyling: false, customClass: {confirmButton: "btn fw-bold btn-light-primary",},
                                      });
                                    }
                                }

                            }
                        }

                        if (vvIsSuccess===false) {
                            emit("on-error", "ERR_CODE_03");
                            Swal.fire({
                                text: "Email gönderme işlemi sırasında bir hata oluştu.", confirmButtonText: "Tekrar dene!-03",
                                icon: "error", buttonsStyling: false, customClass: {confirmButton: "btn fw-bold btn-light-danger",},
                            });
                        }


                    }) .catch(() => {
                        emit("on-error", "ERR_CODE_02");
                        Swal.fire({
                            text: "Email gönderme işlemi sırasında bir hata oluştu.", confirmButtonText: "Tekrar dene!-02",
                            icon: "error", buttonsStyling: false, customClass: {confirmButton: "btn fw-bold btn-light-danger",},
                        });

                    });


                })
            }).catch(() => {
                emit("on-error", "ERR_CODE_01");
                Swal.fire({
                    text: "Dosya Yükleme işlemi sırasında bir hata oluştu.", confirmButtonText: "Tekrar dene!-01",
                    icon: "error", buttonsStyling: false, customClass: {confirmButton: "btn fw-bold btn-light-danger",},
                });
            });
        }


        return {
            refForm,
            validationSchema,
            supportedFiles,
            addFileAndSendEmail,
        };
    },
});

