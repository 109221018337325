
import {computed, defineComponent, onMounted, ref} from "vue";
import {setCurrentPageBreadcrumbs} from "@/core/helpers/breadcrumb";
import store from "@/store";
import {Actions} from "@/store/enums/StoreEnums";
import {Field} from "vee-validate";
import {libTools} from "@/core/appcore/libs/Lib_Tools";
import {dateConvert} from "@/core/filters/datatime";
import flatpickr from "flatpickr";
import {Turkish} from "flatpickr/dist/l10n/tr";
import 'flatpickr/dist/flatpickr.css';
import {useRouter} from "vue-router";
import Swal from "sweetalert2/dist/sweetalert2.js";
import PrintInvoice from "@/components/invoices/invoices/PrintInvoice.vue";
import ReturnInvoice from "@/components/invoices/invoices/ReturnInvoice.vue";


interface srcItem {
  srcInvoiceDate1: string;
  srcInvoiceDate2: string;
  srcInvoiceNo: string;
  srcAccount: number;
  srcDepartment: number;
}

export default defineComponent({
  name: "invoices-list",

  components: {
    Field,
    PrintInvoice,
    ReturnInvoice,
  },



  data: function () {
    return {
      imgUrl: '',
      searchName: '',
      sortField: '',
      sortType: '',
    }
  },

  methods: {
    ...dateConvert.method,

    sortData(attr, data) {
      if (this.sortField == '') {
        this.sortType = "asc";
      } else {
        this.sortType = this.sortField == attr && this.sortType == 'asc' ? "desc" : "asc";
      }
      this.sortField = attr;

      if (this.sortType == 'asc' || this.sortType == 'desc') {
        this[data].sort((a, b) => {
          let fa = '';
          let fb = '';
          if (typeof (attr) == 'string') {
            if (this.sortType == 'asc') {
              fa = a[attr].toLowerCase();
              fb = b[attr].toLowerCase();
            } else {
              fb = a[attr].toLowerCase();
              fa = b[attr].toLowerCase();
            }
            if (fa < fb) {
              return -1;
            }
            if (fa > fb) {
              return 1;
            }
            return 0;
          } else {
            return this.sortType == 'asc' ? (a.Mobile - b.Mobile) : (b.Mobile - a.Mobile);
          }
        });
      }
    }
  },

  setup() {
    const router = useRouter();

    onMounted(()=>{
      setDatePicker();



    });

    const arrInvoicesList = ref([]);
    const submitButtonRef = ref<null | HTMLButtonElement>(null);

    const refComp_PrintInvoice_1 = ref(null);
    const refComp_ReturnInvoice = ref(null);

    const srcItem = ref<srcItem>({
      srcInvoiceDate1: dateConvert.method.dateToflatpicker(dateConvert.method.getTodayYMD()),
      srcInvoiceDate2: dateConvert.method.dateToflatpicker(dateConvert.method.getTodayYMD()),
      srcInvoiceNo: "",
      srcAccount: 0,
      srcDepartment: 0,
    });

    store.dispatch(Actions.ACCOUNT_LIST_ALL, {});
    const myAccountList = computed(() => {
      return store.getters.accountList;
    });

    function setDatePicker() {
      let tmpSrcInvoiceDate1 = flatpickr("#srcInvoiceDate1", {"locale": Turkish, dateFormat: "d-m-Y (D)", defaultDate: 'today',});
      let tmpSrcInvoiceDate2 = flatpickr("#srcInvoiceDate2", {"locale": Turkish, dateFormat: "d-m-Y (D)", defaultDate: 'today',});
    }




    const click_Search = () => {
        if (!submitButtonRef.value) {
          return;
        }
        const payload1 = {
          Date1: srcItem.value.srcInvoiceDate1 ? dateConvert.method.flatpickerToDateYM(srcItem.value.srcInvoiceDate1) : "",
          Date2: srcItem.value.srcInvoiceDate2 ? dateConvert.method.flatpickerToDateYM(srcItem.value.srcInvoiceDate2) : "",
          No: srcItem.value.srcInvoiceNo,
          AccountID: srcItem.value.srcAccount,
        };


        store.dispatch(Actions.INVOICES_LIST, payload1).then((prmData) => {
          arrInvoicesList.value = prmData.data;
          submitButtonRef.value.disabled = false;
          submitButtonRef.value.setAttribute("data-kt-indicator", "off");
        }).catch((prmData) => {
          libTools.method.swalError("Tamam", "Hata Oluştu");
        });
      submitButtonRef.value.disabled = false;
      submitButtonRef.value.setAttribute("data-kt-indicator", "on");
    };

    const deleteInvoice = (ID) => {
      store.dispatch(Actions.INVOICES_LIST, {});
      Swal.fire({
        title: 'Emin misiniz?',
        text: "Kayıt silme işlemi geri alınamaz.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: 'Kaydı Sil',
        cancelButtonText: `Vazgeç`,
        confirmButtonColor: '#9c1d1d',
        cancelButtonColor: '#3085d6',
      }).then(function (result) {
        if (result.isConfirmed) {
          const payload1 = {
            ID: ID,
          }
          store.dispatch(Actions.INVOICES_DELETE, payload1)
              .then(() => {
                store.dispatch(Actions.INVOICES_LIST, {});
                Swal.fire(
                    'Silindi!',
                    'Kayıt silme işlemi tamamlandı.',
                    'success'
                )
              }).catch(() => {
            Swal.fire({
              text: store.getters.getInvoicesErrors[0],
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Tekrar dene!",
              customClass: {
                confirmButton: "btn fw-bold btn-light-danger",
              },
            });
          });

        }
      });
    };





    const itemID = ref<number>(0);
    const invoiceID = ref<number>(0);

    function showReturnInvoice(ID) {
      invoiceID.value=ID;
    }

    function clickShowInvoice(ID) {
      itemID.value=ID;
      (refComp_PrintInvoice_1.value as any).showComp("block");

    }



    onMounted(() => {
      setCurrentPageBreadcrumbs("Fatura Listesi", ["Faturalar"]);
    });

    return {
      dateConvert,
      libTools,
      submitButtonRef,
      srcItem,
      myAccountList,
      click_Search,
      clickShowInvoice,
      deleteInvoice,
      itemID,
      invoiceID,
      arrInvoicesList,
      refComp_PrintInvoice_1,
      showReturnInvoice,
      refComp_ReturnInvoice,


    };
  },
});
