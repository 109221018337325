
import {computed, defineComponent, onMounted, ref,} from "vue";
import store from "@/store";
import {Actions} from "@/store/enums/StoreEnums";
import {libTools} from "@/core/appcore/libs/Lib_Tools";
import {dateConvert} from "@/core/filters/datatime";
import {setCurrentPageBreadcrumbs} from "@/core/helpers/breadcrumb";
import AppCore from "@/core/appcore/AppCore";
import {jsPDF} from "jspdf";
import UploadFile from "@/components/appcomps/uploadfiles/UploadFile.vue";
import SendEmail from "@/components/appcomps/sendemail/SendEmail.vue";


export default defineComponent({
  name: "print-invoice",
  components: {
    SendEmail,
    UploadFile,
  },

  props: {
    itemID: String,
  },

  watch: {
    itemID() {
      const payloadDetails = {
        "ID": this.itemID
      };

      const payloadItems = {
        "InvoiceID": this.itemID
      };

      store.dispatch(Actions.INVOICE_ITEMS_LIST, payloadItems);
      store.dispatch(Actions.INVOICES_DETAILS, payloadDetails);
    },
  },



  setup(props, {emit}) {

    const refBtnExportToPdf = ref(null);
    const refBtnSendEmail = ref(null);
    const refFileUpload = ref(null);
    const refSendEmail = ref(null);
    const refComp_InvoiceBackground = ref(null);
    const refComp_InvoiceNo = ref(null);
    var pWindow;


    store.dispatch(Actions.INVOICE_ITEMS_LIST, {'InvoiceID': props.itemID});
    const invoiceItemList = computed(() => {
      return  store.getters.invoiceitemsList;
    })


    store.dispatch(Actions.INVOICES_DETAILS, {'ID': props.itemID});
    const invoiceDetails = computed(() => {
      return store.getters.invoicesDetails;
    })

    function showComp(prmBackgroundDisplay) {
      (refComp_InvoiceBackground.value as HTMLElement).style.display=prmBackgroundDisplay;
      (refComp_InvoiceNo.value as HTMLElement).style.display=prmBackgroundDisplay;
      console.log("Show Comp " + prmBackgroundDisplay);
    }

    function printInvoice() {
      var divContents = document.getElementById("divInvoice_AllPrintable").innerHTML;
      pWindow = window.open('', '', '');
      pWindow.document.write('<html><body>');
      pWindow.document.write(divContents);
      pWindow.document.write('</body></html>');
      pWindow.document.close();

      setTimeout(()=>{pWindow.print();}, 500);
    }


    function click_ExportToPdf(prmType, prmMailTo) {
      (refComp_InvoiceNo.value as HTMLElement).style.display="block";
      (refComp_InvoiceBackground.value as HTMLElement).style.display="block";
      let elTarget = document.getElementById("divInvoice_AllPrintable");
      elTarget.innerHTML = libTools.method.convertTurkishToEnglish(elTarget.innerHTML);


      if (prmType==="Export") {
        refBtnExportToPdf.value.disabled = true;
        refBtnExportToPdf.value.setAttribute("data-kt-indicator", "on");
      } else {
        refBtnSendEmail.value.disabled = true;
        refBtnSendEmail.value.setAttribute("data-kt-indicator", "on");
      }

      setTimeout(()=>{ exportToPdf(prmType, prmMailTo); }, 500);

    }

    function click_SendEmail() {
      (refSendEmail.value as any).showComp((invoiceDetails.value as any).ReservationEmail);
    }

    function emailEntered(prmEmail) {
      click_ExportToPdf("SendEmail", prmEmail);
    }

    function exportToPdf(prmType, prmMailTo) {


     const doc = new jsPDF('p', 'mm', [1200, 1350]);
      let elTarget = document.getElementById("divInvoice_AllPrintable");
      doc.html(elTarget,
          {
            callback: function(doc) {
              let vvDetail = invoiceDetails.value as any;
              let vvItem = invoiceItemList.value as any;
              let vvDirName = vvDetail.No.replaceAll(".", "-").replaceAll(" ", "");
              let vvFileName = "Invoice"+"_"+ vvDetail.No.replaceAll(".", "-").replaceAll(" ", "") +".pdf";
              let vvKindName = 'Invoice';
              let vvMailTo = prmMailTo;
              let vvContent = "Merhabalar,"+ " " + vvDetail.No + " " +"numaralı fatura bilgisi ektedir";
              let vvToName = "Fatura "+vvDetail.No;
              let vvFromName = "Fatura "+vvDetail.No;
              let vvSubject = "Fatura "+vvDetail.No;

              if (prmType==="Export") {
                doc.save(vvFileName);
                refBtnExportToPdf.value.disabled = false;
                refBtnExportToPdf.value.setAttribute("data-kt-indicator", "off");
              }
              if (prmType==="SendEmail") {
                var vvFile = doc.output('blob');
                (refFileUpload.value).addFileAndSendEmail(vvFile, vvKindName, vvDirName,  vvFileName, "INVOICE", vvMailTo, vvToName, vvFromName, vvSubject, vvDetail.AccountID, vvDetail.ID, vvContent);

              }

            },
            x: 10,
            y: 4,
            image: { type: "png", quality: 0.98 }, // as per req
            html2canvas: { letterRendering: true, useCORS: true },
          }
      );
    }

    function onSuccessUploadFile() {

      refBtnSendEmail.value.disabled = false;
      refBtnSendEmail.value.setAttribute("data-kt-indicator", "off");
      emit("on-success", invoiceItemList.value);
      libTools.method.swalSuccess("Tamam", "Email gönderildi");
    }

    function onErrorUploadFile(prmErrorCode) {
      refBtnSendEmail.value.disabled = false;
      refBtnSendEmail.value.setAttribute("data-kt-indicator", "off");
      libTools.method.swalError("Tamam", "Email gönderilemedi");
    }




    return {
      libTools,
      dateConvert,
      printInvoice,
      click_ExportToPdf, refBtnExportToPdf,
      refFileUpload, refBtnSendEmail, refSendEmail,
      onSuccessUploadFile, onErrorUploadFile,
      click_SendEmail, emailEntered,
      invoiceDetails,
      invoiceItemList,
      showComp,
      refComp_InvoiceBackground, refComp_InvoiceNo,
    };
  },
});
